
import EntityView from "@/components/base/common/EntityView.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {computed, defineComponent, onUpdated, reactive} from "vue";
import {PinaLoadEntity} from "@/core/composite/composite";
import QuickAction from "@/components/base/action/QuickAction.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormUser from "@/views/user/FormUser.vue";
import UserService from "@/core/services/UserService";
import Swal from 'sweetalert2'
import PresetMfaForm from "@/views/user/PresetMfaForm.vue";
import {useUserStore} from "@/store/user.store";
import UserStatus from "@/views/user/UserStatus.vue";
import store from "@/store";

export default defineComponent({
  name: "User",
  components: {UserStatus, PresetMfaForm, FormUser, BaseModal, TabNavigate, DateTimeFormat, QuickAction, EntityView},
  setup() {
    const userStore = useUserStore();
    const currentUser = computed(() => store.getters.currentUser);
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('User Details', [
        {link: true, router: '/company/' + currentUser.value.currentCompanyId + "/users", text: currentUser.value.currentCompany.name},
        {link: false, router: '', text: 'View User'}
      ])
    })
    const state = reactive({
      user: {}
    });
    const user = computed(() => userStore.entity);
    return {
      ...PinaLoadEntity(userStore),
      user,
      state,
    }
  },
  methods: {
    onUpdate(user) {
      this.state.user = user;
    },
    onRestPassword(user) {
      Swal.showLoading()
      UserService.resetPassword(user.id).then(() => {
        Swal.fire({
          title: 'Reset Password Success',
          text: '',
          icon: 'success',
        })
      })
    },
    onSaveUser() {
      const baseModal = this.$refs.userForm as typeof BaseModal
      baseModal.hideBaseModal()
      this.loadEntity()
    },
    onSaveMFA() {
      const baseModal = this.$refs.presetMfaForm as typeof BaseModal
      baseModal.hideBaseModal()
      this.loadEntity()
    },
    onUpdateStatus(user, status) {
      UserService.updateStatus(user.id, status).then(() => {
        this.loadEntity()
      })
    },
  }
})
