
import { defineComponent, ref, watch } from "vue";
import { Field } from 'vee-validate'
import BaseForm from "@/components/base/form/BaseForm.vue";
import * as Yup from "yup";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import UserService from "@/core/services/UserService";

export default defineComponent({
  name: "FormUser",
  components: { FormErrorMessage, BaseForm, Field},
  props: {
    user: {required: true, type: Object},
  },
  emit: ["close"],
  setup(props, {emit}) {
    const formRef = ref<null | HTMLFormElement>(null);
    const submitting = ref<boolean>(false);
    const userForm = ref({
      name: '',
      firstName: '',
      lastName: '',
      description: '',
      companyId: '',
    })
    watch(() => props.user, (u: any) => {
      userForm.value.name = u.name;
      userForm.value.firstName = u.firstName
      userForm.value.lastName = u.lastName
      userForm.value.description = u.description
      userForm.value.companyId = u?.company?.id;
    })

    const validate = Yup.object().shape({
      name: Yup.string().email().required().label("Email"),
      companyId: Yup.string().required().label("Company"),
    });

    const submit = () => {
      submitting.value = true;
      if (props.user.id) {
        UserService.update(props.user.id, userForm.value).then(res => {
          emit("close", res);
        }).finally(() => {
          submitting.value = false;
        })
      } else {
        UserService.create(userForm.value).then(res => {
          emit("close", res)
        }).finally(() => {
          submitting.value = false;
        })
      }
    }
    const discard = () => {
      userForm.value = {
        name: '',
        firstName: '',
        lastName: '',
        description: '',
        companyId: '',
      }
    }
    return {
      userForm,
      validate,
      formRef,
      submitting,
      submit,
      discard,
    }
  }
})
