import {defineStore} from "pinia";
import {useLoadingStore} from "@/store/loading";
import UserService from "@/core/services/UserService";

export const useUserStore = defineStore('userStore', {
  state: () => ({
    page: {
      total: 0,
      data: [],
      totalPages: 0
    },
    entity: {}
  }),
  actions: {
    list(filter) {
      const loadingStore = useLoadingStore();
      loadingStore.setEntitiesLoading(true)
      UserService.findAll<any>(filter).then(res => {
        this.page = res;
      }).finally(() => {
        loadingStore.setEntitiesLoading(false)
      })
    },
    get(id) {
      UserService.get<any>(id).then(res => {
        this.entity = res
      })
    }
  }
})